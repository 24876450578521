
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "customer-financial-statements",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Financial Statements", ["Customer"]);
    });

    return {};
  },
  data() {
    let isLoading = true;
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;

    return {
      router: useRouter(),
      cstmr_id,
    };
  },
  methods: {
    handleCommandAddNewStatement(command) {
      let route = this.$router.resolve({
        path: `/financial-statements/sme/${this.cstmr_id}/${command}`,
      });
      window.open(route.href);
    },
  },
});
